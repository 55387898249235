@if (showTimeLane) {
    <app-time-lane [timeRange]="timeRangeValue" [currentTime]="currentTime()"/>
}

<div class="bg-gray-50 relative" [style.min-height.px]="minHeight">
    @for (bg of backgrounds; track $index) {
        <div class="absolute h-full" [ngClass]="bg.styleClass" [appLanePosition]="bg.range"></div>
    }
    @for (tick of hourTicks; track $index) {
        <div class="absolute bg-de-emphasized w-[1px] h-[10px]" [appLanePosition]="tick" matTooltip="{{tick | time}}"></div>
    }
    <div class="absolute h-full w-[2px] bg-black" [appLanePosition]="currentTime()"></div>

    <ng-content select=":not(.bottom)"></ng-content>
</div>

<ng-content select=".bottom"></ng-content>
