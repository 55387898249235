import { ChangeDetectionStrategy, Component, Input, Signal } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Duration, hoursInRange, Instant, InstantRange, midnightsInRange, minuteChanges, TimePipe } from "common";
import { distinctUntilChanged } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { TimeLaneComponent } from "./time-lane.component";
import { LanePositionDirective } from "./lane-position.directive";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgClass } from "@angular/common";

type BackgroundType = "zebra-day" | "night-time";

/**
 * A general component for displaying stuff in a timeline.
 */
@Component({
    selector: 'app-timeline',
    templateUrl: 'timeline.component.html',
    styles: `
        :host {
            display: block;
            overflow-x: hidden;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TimeLaneComponent,
        LanePositionDirective,
        MatTooltipModule,
        TimePipe,
        NgClass,
    ],
    host: {
        '[class.has-timeline]': 'hasTimeline()',
    }
})
export class TimelineComponent {

    private readonly _timeRange = new BehaviorSubject<InstantRange | null>(null);
    @Input() showTimeLane = false;
    @Input() minHeight = 50;
    private _backgroundType: BackgroundType = "night-time";
    private _showTicks = true;

    readonly timeRange$: Observable<InstantRange | null> = this._timeRange.asObservable()
        .pipe(distinctUntilChanged((x, y) => (x == null && y == null) || (x != null && y != null && x.equals(y))));

    hourTicks: Instant[] = [];
    backgrounds: Background[] = [];

    readonly currentTime: Signal<Instant> = minuteChanges();

    hasTimeline(): boolean {
        return this.showTimeLane;
    }

    get timeRangeValue(): InstantRange | null {
        return this._timeRange.getValue();
    }

    // get timeRange must be different name because result type differs from setter parameter type
    @Input()
    set timeRange(range: InstantRange | undefined) {
        const currentValue = this._timeRange.getValue();
        if (range != null && (currentValue == null || !currentValue.equals(range))) {
            this._timeRange.next(range);

            this.recalculateMarkers();
        }
    }

    @Input()
    set backgroundType(background: BackgroundType) {
        if (background !== this._backgroundType) {
            this._backgroundType = background;

            this.recalculateMarkers();
        }
    }

    @Input()
    set showTicks(showTicks: boolean) {
        const v = coerceBooleanProperty(showTicks);
        if (v !== this._showTicks) {
            this._showTicks = v;
            this.recalculateMarkers();
        }
    }

    private recalculateMarkers(): void {
        const range = this._timeRange.getValue();
        if (range) {
            this.hourTicks = this._showTicks ? hoursInRange(range) : [];

            switch (this._backgroundType) {
                case "night-time":
                    this.backgrounds = midnightsInRange(range).map(midnight => ({
                        // for now, assume that nighttime is between 22-08
                        range: new InstantRange(midnight.minus(Duration.ofHours(2)), midnight.plus(Duration.ofHours(8))),
                        styleClass: "bg-[#d6e8f9]"
                    }));
                    break;
                case "zebra-day":
                    this.backgrounds = midnightsInRange(range).map((midnight, i) => ({
                        range: new InstantRange(midnight, midnight.plus(Duration.ofHours(24))),
                        styleClass: i % 2 === 0 ? "" : "bg-[#d6e8f9]"
                    }));
            }
        } else {
            this.backgrounds = [];
        }
    }
}

interface Background {
    readonly range: InstantRange;
    readonly styleClass: string;
}
