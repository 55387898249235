import { ChangeDetectionStrategy, Component, computed, Input, Signal, signal } from "@angular/core";
import { Duration, HelsinkiDatePipe, Instant, InstantRange, midnightsInRange } from "common";
import { LanePositionDirective } from "./lane-position.directive";

@Component({
    selector: 'app-time-lane',
    templateUrl: './time-lane.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HelsinkiDatePipe,
        LanePositionDirective,
    ]
})
export class TimeLaneComponent {

    private readonly _timeRange = signal(new InstantRange(Instant.now(), Instant.now()));
    private readonly _currentTime = signal(Instant.now());

    readonly times: Signal<Instant[]> = computed(() => recalculateTimes(this._timeRange(), this._currentTime()));

    get timeRange(): InstantRange {
        return this._timeRange();
    }

    @Input()
    set timeRange(range: InstantRange | null) {
        if (range != null)
            this._timeRange.set(range);
    }

    get showStart(): boolean {
        return farEnoughToShowBoth(this._currentTime(), this._timeRange().start);
    }

    get showEnd(): boolean {
        return farEnoughToShowBoth(this._currentTime(), this._timeRange().end);
    }

    @Input()
    set currentTime(time: Instant) {
        this._currentTime.set(time);
    }
}

function recalculateTimes(range: InstantRange, currentTime: Instant): Instant[] {
    let result = midnightsInRange(range).filter(t => farEnoughToShowBoth(t, range.start) && farEnoughToShowBoth(t, range.end));

    if (range.contains(currentTime)) {
        result = result.filter(t => farEnoughToShowBoth(t, currentTime));
        result.push(currentTime);
    }

    return result;
}

function farEnoughToShowBoth(time: Instant, time2: Instant): boolean {
    return Duration.between(time, time2).abs().toMinutes() > 120;
}
