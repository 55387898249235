import { Directive, Input, OnDestroy } from "@angular/core";
import { Instant, InstantRange, ratioOfTimeBetween, unnormalizedRatioOfTimeBetween } from "common";
import { TimelineComponent } from "./timeline.component";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";

type Position = Instant | InstantRange | null;

/**
 * Sets the position attributes of an element within a lane automatically
 * based on given time values.
 */
@Directive({
    selector: '[appLanePosition]',
    standalone: true,
    host: {
        '[style.left.%]': 'styleLeft',
        '[style.right.%]': 'styleRight',
        '[style.opacity]': 'styleOpacity',
    },
})
export class LanePositionDirective implements OnDestroy {

    private readonly _appLanePosition = new BehaviorSubject<Position | null>(null);

    private readonly subscription: Subscription;

    styleLeft: number | null = null;

    styleRight: number | null = null;

    // style.hidden would be nicer way to hide, but does not work if style rules override display
    styleOpacity: number | null = null;

    /**
     * We can specify the position as Instant or InstantRange. If an Instant
     * is given, then the left position is calculated based on that time. If
     * a range is given, then both left and right position are calculated.
     */
    @Input() set appLanePosition(position: Position) {
        if (!equalPositions(this._appLanePosition.value, position))
            this._appLanePosition.next(position);
    }

    private recalculate(position: Position | null, range: InstantRange | null): void {
        let visible: boolean;
        if (position == null || range == null) {
            this.styleLeft = null;
            this.styleRight = null;
            visible = false;
        } else if (position instanceof Instant) {
            this.styleLeft = ratioOfTimeBetween(range, position) * 100;
            this.styleRight = null;
            visible = range.contains(position);
        } else {
            this.styleLeft = 100 * unnormalizedRatioOfTimeBetween(range, position.start);
            this.styleRight = 100 - (100 * unnormalizedRatioOfTimeBetween(range, position.end));
            visible = range.overlaps(position);
        }

        this.styleOpacity = visible ? null : 0;
    }

    constructor(timeline: TimelineComponent) {
        this.subscription = combineLatest([this._appLanePosition, timeline.timeRange$]).subscribe(([appLanePosition, timeRange]) => {
            this.recalculate(appLanePosition, timeRange);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

function equalPositions(oldPos: Position | null, newPos: Position | null): boolean {
    if (newPos instanceof Instant && oldPos instanceof Instant) {
        return newPos.equals(oldPos);

    } else if (newPos instanceof InstantRange && oldPos instanceof InstantRange) {
        return newPos.equals(oldPos);

    } else {
        return newPos === oldPos;
    }
}
