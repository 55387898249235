import { Pipe, PipeTransform } from "@angular/core";
import { formatInstantRange, InstantRange } from "common";

@Pipe({name: 'timeRange', standalone: true, pure: true})
export class TimeRangePipe implements PipeTransform {

    transform(value: InstantRange): string {
        return formatInstantRange(value);
    }
}
