<div class="text-[8pt] h-[20px]">
    @if (showStart) {
        <time class="float-left">{{ timeRange.start | helsinkiDate:'dd.MM. HH:mm' }}</time>
    }
    @if (showEnd) {
        <time class="float-right">{{ timeRange.end | helsinkiDate:'dd.MM. HH:mm' }}</time>
    }

    @for (time of times(); track $index) {
        <div class="absolute" [appLanePosition]="time">
            <time class="whitespace-nowrap" style="margin-left: -50%">{{ time | helsinkiDate:'dd.MM. HH:mm' }}</time>
        </div>
    }
</div>
